.orderItemContainer {
  width: 100%;
  // min-height: 5.85in;
  // max-height: 5.85in;
  display: flex;
  flex-wrap: wrap;
  border: 1px solid gray;
  margin-bottom: 10px;

  .user_info {
    width: 100%;
    background: #35744b;
    color: white;
    padding: 5px;
    padding-left: 10px;
  }

  .column-title {
    padding-left: 10px;
    background: rgb(207, 206, 206);
  }
  .user_info {
    position: relative;
  }

  .user_info:after {
    z-index: 1;
    position: absolute;
    content: '';
    background-image: url(https://themntable.com/wp-content/uploads/2019/09/logo.png);
    /* width: 100%;
    height: 100%;
    opacity: 0.1;
    background-size: 130px;
    background-repeat: no-repeat;
    background-position: 8px center; */
    background-size: contain;
    top: 5px;
    right: 10px;
    width: 65px;
    height: 64px;
    filter: invert(10) brightness(10);
  }

  .reciept_content {
    display: flex;
    width: 100%;
    > div {
      min-width: 50%;
      margin-right: 2px;
    }
  }

  .note_content p {
    padding: 10px;
  }
  .single-item {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    background: #e9e9e9;
    div {
      min-width: 50px;
    }
    .item-name {
      flex-grow: 1;
    }
    .item-size,
    .item-qnty {
      text-align: center;
      min-width: 50px;
    }
    .item-qnty.more_one {
      background: #1a3b25;
      color: white;
    }
  }
  .custom-1-2 {
    background: #b1f4ca;
  }
  .custom-3-4 {
    background: #cdcdfc;
  }
  .custom-5-6 {
    background: #f4b1c5;
  }

  .column-title {
    display: flex;
    .label-name {
      flex-grow: 1;
    }
    .label-size,
    .label-qty {
      min-width: 50px;
      text-align: center;
    }
  }
}
