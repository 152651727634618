.orderContainer {
  box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.3);
  width: 8.3in;
  // height: 11.7in;
  margin: auto;
}

@page {
  size: A4;
  margin: 0 !important; /* this affects the margin in the printer settings */
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
  color-adjust: exact !important; /*Firefox*/
}

@media print {
  .orderContainer {
    display: table !important;
  }
  .header {
    display: none !important;
  }

  .orderItemContainer {
    page-break-inside: avoid;
    display: block !important;
    height: auto;
  }

  body {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
    margin: 0;
  }
}
