.product-item {
  background-color: white;

  page-break-after: always;
  height: 2in;
  width: 2in !important;
  overflow: hidden;
  position: relative;
  margin-left: 0.3in;
  margin-right: 0.3in;
  margin-bottom: 0.3in;
  margin-top: 0.3in;
  display: inline-flex !important;

}

.product-item:after {
  z-index: 1;
  position: absolute;
  content: '';
  background-image: url(https://themntable.com/wp-content/uploads/2019/09/logo.png);
  /* width: 100%;
	height: 100%;
	opacity: 0.1;
	background-size: 130px;
	background-repeat: no-repeat;
	background-position: 8px center; */
  background-size: contain;
  top: 20px;
  left: 20px;
  width: 35px;
  height: 35px;
}

/* sizes */
.indicators {
  display: none;
  position: absolute;
  top: 10px;
  right: 2px;
  background: #e8f1ec;
  color: #40694e;
  padding: 5px;
}

.entree-message {
  font-size: 12px;
  text-align: center;
  line-height: 1;
}
/* #order-container div:nth-child(3n) {
	margin-right: 0px !important;
} */

.product-item > * {
  z-index: 2;
}

#order-container {
  height: 1056px;
  width: 816px;
  margin: auto;
}

/* #order-container div:nth-child(21n+0) {margin-bottom:60px;} */

.product-title {
  font-variant: all-petite-caps;
  line-height: 1;
  font-size: 17px !important;
}

.serv-size {
  display: none;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  padding: 2px;
  text-align: center;
  color: white;
}

.size-1-2 {
  background-color: #1abc9c;
}
.size-3-4 {
  background-color: #3498db;
}
.size-5-6 {
  background-color: #34495e;
}

/* iconIndicators  */

.iconIndicators {
  position: absolute;
  top: 10px;
  right: 2px;
  /* background: #e8f1ec; */
  /* color: #40694e; */
  filter: contrast(50%);
  padding: 5px;
  display: flex;
}

.iconIndicators img {
  width: 18px;
  height: 18px;
  margin-left: 3px;
  margin-right: 3px;
}

@media print {
  #order-container div:nth-child(3n + 1) {
    margin-left: 0.6in !important;
  }
  
  #order-container div:nth-child(12n + 1) {
    margin-top: 0.6in !important;
  }
  #order-container div:nth-child(12n + 2) {
    margin-top: 0.6in !important;
  }
  #order-container div:nth-child(12n + 3) {
    margin-top: 0.6in !important;
  }
  
  /* #order-container div:nth-child(12n + 10) {
    margin-bottom: 75.59px !important;
  } */
  
  #order-container div:nth-child(12n + 10),
  #order-container div:nth-child(12n + 11),
  #order-container div:nth-child(12n + 12) {
    margin-bottom: 0.6in !important;
  }
  
  #order-container div:nth-child(12n + 13),
  #order-container div:nth-child(12n + 14),
  #order-container div:nth-child(12n + 15) {
    margin-top: 0.6in !important;
  }
  
  #order-container {
    display: inline-flex !important;
 }
  .timeframe,
  .header {
    display: none;
  }
  .printBtnContainer {
    display: none !important;
  }
  html,
  body {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
    color-adjust: exact !important; /*Firefox*/
    margin: 0 !important;
  }
}

@page {
  size: portrait;
  margin: 0 !important; /* this affects the margin in the printer settings */
  -webkit-print-color-adjust: exact !important; /* Chrome, Safari */
  color-adjust: exact !important; /*Firefox*/
}

/* REACT DATEPICKER STYLES */
.react-datepicker {
  font-family: unset;
  font-size: 0.9rem;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block;
}

.react-datepicker__input-container {
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: 1px solid;
  border-color: hsl(0, 0%, 80%);
}

.react-datepicker__input-container:hover {
  border-color: hsl(0, 0%, 70%);
}
.react-datepicker__input-container:focus-within {
  z-index: 1;
  border-color: #3182ce;
  box-shadow: 0 0 0 1px #3182ce;
}

.react-datepicker__input-container > input {
  width: 100%;
  height: 100%;
  outline: 0;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 90px;
}

.react-datepicker__navigation--previous,
.react-datepicker__navigation--next {
  height: 40px;
}

.react-datepicker__navigation--previous {
  border-right-color: #cbd5e0;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #a0aec0;
}

.react-datepicker__navigation--next {
  border-left-color: #cbd5e0;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #a0aec0;
}

.react-datepicker__header {
  background: #f7fafc;
}

.react-datepicker__header,
.react-datepicker__time-container {
  border-color: #e2e8f0;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: inherit;
  font-weight: 600;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  margin: 0 1px 0 0;
  height: auto;
  padding: 7px 10px;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item:hover {
  background: #edf2f7;
}

.react-datepicker__day:hover {
  background: #edf2f7;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background: #3182ce;
  font-weight: normal;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected:hover {
  background: #2a69ac;
}

.react-datepicker__close-icon::after {
  background-color: unset;
  border-radius: unset;
  font-size: 1.5rem;
  font-weight: bold;
  color: hsl(0, 0%, 80%);
  height: 20px;
  width: 20px;
}

.react-datepicker__close-icon::after:hover {
  color: hsl(0, 0%, 70%);
}
